$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 767px,
        lg: 991px,
        xl: 1200px
);

$link-hover-decoration: none; // remove underline from button links

$link-color: #3f51b5; // set link color based on the used material theme
$link-hover-color: currentColor;

// #3f51b5 = rgba(63,81,181,1)

$ube: #9999ff;
$butter: #fbdc70;
$aperol: #f05e40;
$mojito: #8addcd;
